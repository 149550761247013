import React from 'react';
import { Container, Image, NavLink } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import Slider from "react-slick";
import StarIcon from '@mui/icons-material/Star';
import google_icon from "../../Assets/images/google-reviews.png";
import rcs_quote_new from "../../Assets/images/home/quote.png";
import yelp_icon from "../../Assets/images/home/yelp.png";
import dummy_user from "../../Assets/images/home/dummy_user.png";
import { useHistory } from 'react-router-dom';
import { client_name } from '../../Helpers/request';
import headingborderImg from '../../Assets/images/line-black.png'

const Testimonial = () => {
    const history = useHistory();
    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const GoogleReview = [
        {
            name:"Kyaira Brown",
            reviewText: "⭐️⭐️⭐️⭐️⭐️ This review is OVERDUE!!! First, THANK YOU SO MUCH FOR ABSOLUTELY EVERYTHING TONY!!! Not only did you educate us on our purchase" ,
            date:"a week ago",
        },
        {
            name:"Jane Simone",
            reviewText: "Re-set and Resplendent!  I brought my plain, boring sapphire ring to Cultured Brilliance.  Tony helped me to design a more classic and timeless setting" ,
            date:"3 months ago",
        },
        {
            name:"Vaidehi Gajjar",
            reviewText: "I actually didn’t get to know Kathleen, the mastermind behind my engagement ring (and now wedding band) until much after my proposal." ,
            date:"a month ago",
        },
        {
            name:"April Cotney",
            reviewText: "Tony was excellent in explaining the whole process from beginning to end of Lab Created Diamonds. He was patient with all of our questions and he completely exceeded my expectations! " ,
            date:"3 months ago",
        },
        {
            name:"Jessica Anders",
            reviewText: "My fiancé worked on my ring for months. Kathleen and Tony worked with my him on three different designs. They truly helped my Fiancé create my dream ring" ,
            date:"4 months ago",
        },
         {
            name:"Gary Tudor",
            reviewText: "Tony made our engagement ring purchase such a pleasure!  We felt so good about the process, quality, cost and service that we never even looked anywhere else." ,
            date:"2 weeks ago",
        },
    ]
    return (
        <>
            <div className="rcs_testimonials">
                <Container>
                    <div className='cb_main_heading'>
                       <h2>WHAT OUR CLIENTS SAY</h2>
                       <Image src={headingborderImg} alt="Heading Border Img"/>
                    </div>
                    <Slider className="rcs_testimonial_inner" {...settings2}>
                      {GoogleReview.map((val, index)=> 
                      <div key={index} className="rcs_testi_item">
                      <NavLink onClick={() => window.open("https://www.google.com/search?q=cultured+brilliance&rlz=1C1UEAD_enIN948IN948&oq=cultured+brilliance&aqs=chrome.0.69i59j46i175i199i512j0i512j69i60j69i61.4325j0j7&sourceid=chrome&ie=UTF-8#lrd=0x88f515eddeb70489:0x63f009a51e2e5682,1", "_blank")} to='#'>
                              <img alt="Google Review" className="google_rev_img" src={google_icon} /> </NavLink>
                          {/* <img className="rcs_quote" src={rcs_quote_new} /> */}
                          <p>{val.reviewText}</p>
                          <div className="rcs_test_star">
                              <StarIcon />
                              <StarIcon />
                              <StarIcon />
                              <StarIcon />
                              <StarIcon />
                          </div>
                          <NavLink onClick={() => window.open("https://www.google.com/search?q=cultured+brilliance&rlz=1C1UEAD_enIN948IN948&oq=cultured+brilliance&aqs=chrome.0.69i59j46i175i199i512j0i512j69i60j69i61.4325j0j7&sourceid=chrome&ie=UTF-8#lrd=0x88f515eddeb70489:0x63f009a51e2e5682,1", "_blank")} to='#'>
                              {/* <img className="rcs_usercircle" src="https://lh3.googleusercontent.com/a-/AOh14GhU0LSDscb0v_UfKgVKEsosHZbH3t5CjhYNq-Tc=w60-h60-p-rp-mo-br100" /> */}
                              <div className="rcs_testi_user"> <h4>{val.name}</h4> </div> </NavLink>
                      </div>)}  
                 
                    </Slider>
                    {/* <div className="rcs_google_review"> <div className="text-center" onClick={() => window.open("https://www.google.com/search?q=cultured+brilliance&rlz=1C1UEAD_enIN948IN948&oq=cultured+brilliance&aqs=chrome.0.69i59j46i175i199i512j0i512j69i60j69i61.4325j0j7&sourceid=chrome&ie=UTF-8#lrd=0x88f515eddeb70489:0x63f009a51e2e5682,1", "_blank")}> <span className="nowrap"><strong>Google</strong>  rating score: </span><span className="nowrap"><strong>5.0</strong> of 5, </span><span className="nowrap">based on <strong>965 reviews</strong>.</span> </div> </div> */}
                </Container>
            </div>
        </>
    )
}

export default Testimonial;