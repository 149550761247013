import React from 'react'
import CustomeDesignVideo from "../../Assets/images/cultured-img/custom-design-video-new.mp4";
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useEffect, useRef } from 'react'
const CustomDesignVideo = () => {
  const history = useHistory();
  const videoRef = useRef(null)

  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current) {
        try {
          await videoRef.current.play()
        } catch (error) {
          console.error("Autoplay was prevented:", error)
        }
      }
    }

    playVideo()
  }, [])
  return (
    <div className='custom_design_video_section cb_custom_j_vid'>
        <Container>
            <Row className='cb_custumdesign_viedo_row align-items-center'>
            <Col sm={12} md={12} lg={6}>
              <div className="cb_ring_home_texxt">
                <h2>Custom Jewelry </h2>
                <p>
                At Cultured Brilliance, we see jewelry as a canvas waiting for your unique brushstrokes. 
                Therefore, we invite you to create your dream ring, to bring your vision to life in a beautiful custom-made ring. 
                We only use certified lab-grown diamonds, so you get the perfect ring that lasts a lifetime.
                </p>
              </div>
              <div className='cd_homer_make_btn_box'>
              <button className='cd_btn_rama_color' onClick={()=>history.push("/custom-design")}> CUSTOM</button>
             </div>
            </Col>
            <Col sm={12} md={12} lg={6} >
            <video ref={videoRef} width="100%" height="100%" autoPlay="autoplay" loop playsInline muted className="cbdga_custu">
                <source src={CustomeDesignVideo} type="video/mp4" />
                <source src={CustomeDesignVideo} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </Col>
            </Row>
        </Container>
    </div>
  )
}

export default CustomDesignVideo