import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CdVideo from "../../Assets/images/cultured-img/why-cb-video.mp4";
import { useHistory } from "react-router-dom";
import { useEffect, useRef } from 'react'
const CbWhyCulturedBrilliance = () => {
  const history = useHistory();
  const videoRef = useRef(null)

  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current) {
        try {
          await videoRef.current.play()
        } catch (error) {
          console.error("Autoplay was prevented:", error)
        }
      }
    }

    playVideo()
  }, [])
  return (
    <>
      <div className="cb_WhyCulturedBrilliance_section ">
        <Container>
          <Row className='align-items-center'>
            <Col sm={12} md={12} lg={6} className="order_1">
              <div className="cb_ring_home_texxt">
                <h2>Why Cultured Brilliance? </h2>
                <p>
                  Our studio showroom is a comfortable place where customers can
                  relax, ask questions, get answers and really understand what
                  they need to know about buying diamonds. Tony and Kathleen
                  have 40+ years in the jewelry business. We understand that
                  buying an engagement ring is "kind of a big deal", so we go
                  the extra mile to make sure our customers are happy and
                  confident in their purchase. Check out our  REVIEWS to see
                  what others are saying. Now you know why, so give us a call!{" "}
                </p>
              </div>
              <div className='cd_homer_make_btn_box'>
                             <button className='cd_btn_rama_color' onClick={()=> window.open("https://www.google.com/search?q=cultured+brilliance&rlz=1C1UEAD_enIN948IN948&oq=cultured+brilliance&aqs=chrome.0.69i59j46i175i199i512j0i512j69i60j69i61.4325j0j7&sourceid=chrome&ie=UTF-8#lrd=0x88f515eddeb70489:0x63f009a51e2e5682,1", "_blank")}>Reviews</button>
                             <button className='cd_btn_rama_color' onClick={()=>history.push("/contact")}> CONTACT CONCIERGE</button>
             </div>
            </Col>
            <Col sm={12} md={12} lg={6} className="order_2">
              <video ref={videoRef} width="100%" height="100%" autoPlay="autoplay" loop playsInline muted>
                <source src={CdVideo} type="video/mp4" />
                <source src={CdVideo} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CbWhyCulturedBrilliance;
